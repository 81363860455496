import Order from '../models/Order';
import {OrderType} from '../enums/OrderType';
import ArticleGroup from '../models/ArticleGroup';
import Venue from '../models/Venue';
import {PreorderType} from '../enums/PreorderType';
import ArticleOption from '../models/ArticleOption';
import OptionGroup from '../models/OptionGroup';
import FulfilledDependency from '../models/FulfilledDependency';
import {Moment} from 'moment';
import {TimeUtils} from './time-utils';
import {TerminalorderType} from '../enums/TerminalorderType';
import {PromoCodeType} from '../models/PromoCodeType';
import {TranslateService} from '@ngx-translate/core';
import PromoCode from '../models/PromoCode';
import {DisplayIdentifier} from '../enums/DisplayIdentifier';
import Article from '../models/Article';
import {ValidationUtils} from './validation-utils';
import {calculateGeoDistance} from './maps-utils';
import TagUtils from './tag-utils';
import {Fee} from '../models/MovAndFee';
import {
	defaultsToArticleOption,
	getPrice,
	getRequiredArticles,
	numberD,
	numberToCurrency,
	removeFromArray
} from './utils';
import {AnalyticsService} from '../services/analytics/analytics.service';
import {Coupon, CouponType} from '../models/Coupon';

export class OrderUtils {
	static formattedOrderTotalPrice(
		order: Order,
		deliveryFee: boolean,
		promoCode: boolean,
		coupon: boolean
	): string {
		return numberToCurrency(
			OrderUtils.orderTotalPrice(order, deliveryFee, promoCode, coupon),
			order ? order.currency : null
		);
	}

	static totalPriceByOrder(articleGroup: ArticleGroup, order: Order): number {
		return this.totalPrice(
			articleGroup,
			order.type,
			order.preorder?.type,
			order.terminalorder?.type
		);
	}
	static orderTotalPriceWithAllDiscounts(
		order: Order,
		deliveryFee: boolean,
		promoCode: boolean,
		coupon: boolean,
		tip: number = 0
	) {
		const sumOfAllArticles =
			+order.orderedArticles
				.map(orderedArticle => {
					const casted: any = orderedArticle;
					if (casted.totalPrice) {
						return numberD(casted.totalPrice);
					}
					return OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					);
				})
				.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0) + tip;
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let promoValue = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				// case PromoCodeType.ABSOLUTE:
				// 	promoValue = +order.promoCode.value;
				// 	break;
				// case PromoCodeType.RELATIVE:
				// 	promoValue = articlePrices * +order.promoCode.value;
				// 	break;
				case PromoCodeType.DELIVERY_FEE:
					promoValue = fees;
					break;
				case PromoCodeType.FREE_ARTICLE:
					promoValue = OrderUtils.totalPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.BOGO:
					promoValue = OrderUtils.bogoPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
			}
		}
		let couponValue = 0;
		if (coupon && order?.coupon?.length > 0) {
			order.coupon.forEach(it => {
				switch (it.type) {
					// case CouponType.ABSOLUTE:
					// 	couponValue = couponValue + it.value;
					// 	break;
					// case CouponType.RELATIVE:
					// 	couponValue = couponValue + articlePrices * +it.value;
					// 	break;
					case CouponType.DELIVERY_FEE:
						couponValue = couponValue + fees;
						break;
					case CouponType.FREE_ARTICLE:
						const articleFree = order.orderedArticles.find(
							articleGroup =>
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (articleFree) {
							const articlePriceFOfFree = this.totalPrice(
								articleFree,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePriceFOfFree;
						}

						break;
					case CouponType.BOGO:
						couponValue =
							couponValue +
							OrderUtils.bogoPrice(
								order.orderedArticles.find(artGrp => artGrp.isPromo),
								order.type,
								order.preorder?.type
							);
					case CouponType.PLUS_ONE:
						const article = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (article) {
							const articlePrice = this.totalPrice(
								article,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePrice;
						}

						break;
					case CouponType.RELATIVE_ARTICLE:
						const articleDiscount = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								it.value?.articles?.includes(articleGroup?.article?._id)
						);
						if (articleDiscount) {
							const articlePrice = this.totalPrice(
								articleDiscount,
								order.type,
								order.preorder.type
							);
							const couponPrice = articlePrice * +it.value?.discount;

							couponValue = couponValue + couponPrice;
						}

						break;
				}
			});
		}
		const totalSumWithCouponDiscounts =
			sumOfAllArticles + fees - promoValue - couponValue;
		let promoValueDiscount = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					promoValueDiscount = +order.promoCode.value;
					break;
				case PromoCodeType.RELATIVE:
					promoValueDiscount =
						totalSumWithCouponDiscounts * +order.promoCode.value;
					break;
			}
		}
		let couponValueDiscount = 0;

		if (coupon && order?.coupon?.length > 0) {
			order.coupon.forEach(it => {
				switch (it.type) {
					case CouponType.ABSOLUTE:
						couponValueDiscount = couponValueDiscount + it.value;
						break;
					case CouponType.RELATIVE:
						couponValueDiscount =
							couponValueDiscount + totalSumWithCouponDiscounts * +it.value;
						break;
				}
			});
		}
		return (
			totalSumWithCouponDiscounts - promoValueDiscount - couponValueDiscount
		);
	}
	static orderTotalPriceWithAllDiscountsStatistics(
		order: Order,
		deliveryFee: boolean,
		promoCode: boolean,
		coupon: boolean,
		tip: number = 0
	) {
		const sumOfAllArticles =
			+order.orderedArticles
				.map(orderedArticle => {
					const casted: any = orderedArticle;
					if (casted.totalPrice) {
						return numberD(casted.totalPrice);
					}
					return OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder?.type,
						order.terminalorder?.type
					);
				})
				.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0) + tip;
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let promoValue = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.DELIVERY_FEE:
					promoValue = fees;
					break;
				case PromoCodeType.FREE_ARTICLE:
					promoValue = OrderUtils.totalPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.BOGO:
					promoValue = OrderUtils.bogoPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
			}
		}
		let couponValue = 0;
		if (coupon && order?.coupon?.length > 0) {
			order.coupon.forEach(it => {
				switch (it.type) {
					case CouponType.DELIVERY_FEE:
						couponValue = couponValue + fees;
						break;
					case CouponType.FREE_ARTICLE:
						const articleFree = order.orderedArticles.find(
							articleGroup =>
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (articleFree) {
							const articlePriceFOfFree = this.totalPrice(
								articleFree,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePriceFOfFree;
						}

						break;
					case CouponType.BOGO:
						couponValue =
							couponValue +
							OrderUtils.bogoPrice(
								order.orderedArticles.find(artGrp => artGrp.isPromo),
								order.type,
								order.preorder?.type
							);
						break;
					case CouponType.PLUS_ONE:
						const article = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (article) {
							const articlePrice = this.totalPrice(
								article,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePrice;
						}

						break;
					case CouponType.RELATIVE_ARTICLE:
						const articleDiscount = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								it.value?.articles?.includes(articleGroup?.article?._id)
						);
						if (articleDiscount) {
							const articlePrice = this.totalPrice(
								articleDiscount,
								order.type,
								order.preorder.type
							);
							const couponPrice = articlePrice * +it.value?.discount;

							couponValue = couponValue + couponPrice;
						}

						break;
				}
			});
		}
		const totalSumWithCouponDiscounts =
			sumOfAllArticles + fees - promoValue - couponValue;
		let promoValueDiscount = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					promoValueDiscount = +order.promoCode.value;
					break;
				case PromoCodeType.RELATIVE:
					promoValueDiscount =
						totalSumWithCouponDiscounts * +order.promoCode.value;
					break;
			}
		}
		let couponValueDiscount = 0;

		if (coupon && order?.coupon?.length > 0) {
			order.coupon.forEach(it => {
				switch (it.type) {
					case CouponType.ABSOLUTE:
						couponValueDiscount = couponValueDiscount + it.value;
						break;
					case CouponType.RELATIVE:
						couponValueDiscount =
							couponValueDiscount + totalSumWithCouponDiscounts * +it.value;
						break;
				}
			});
		}
		return {
			finalPrice:
				totalSumWithCouponDiscounts - promoValueDiscount - couponValueDiscount,
			promocodeDiscount: promoValueDiscount,
			couponsDiscount: {
				absolute: couponValueDiscount,
				custom: couponValue
			},
			totalPriceOfAllArticles: sumOfAllArticles
		};
	}
	static promoCodeDiscount(
		order: Order,
		deliveryFee: boolean,
		promoCode: boolean
	) {
		const articlePrices = +order.orderedArticles
			.map(orderedArticle => {
				const casted: any = orderedArticle;
				if (casted.totalPrice) {
					return numberD(casted.totalPrice);
				}
				return OrderUtils.totalPrice(
					orderedArticle,
					order.type,
					order.preorder?.type,
					order.terminalorder?.type
				);
			})
			.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0);
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let promoValue = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					promoValue = +order.promoCode.value;
					break;
				case PromoCodeType.RELATIVE:
					promoValue = articlePrices * +order.promoCode.value;
					break;
				case PromoCodeType.DELIVERY_FEE:
					promoValue = fees;
					break;
				case PromoCodeType.FREE_ARTICLE:
					promoValue = OrderUtils.totalPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.BOGO:
					promoValue = OrderUtils.bogoPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.RELATIVE_ARTICLE:
					const articleDiscountArray = order.orderedArticles.filter(
						articleGroup =>
							order.promoCode.value?.articles?.includes(
								articleGroup?.article?._id || articleGroup?.article?.masterId
							)
					);
					articleDiscountArray.forEach(articleDiscountItem => {
						if (articleDiscountItem) {
							let couponPrice = 0;

							const articlePriceWithoutOptions =
								this.articlePriceWithoutOptions(
									articleDiscountItem,
									order.type,
									order.preorder.type
								);

							if (order.promoCode.applyOnAnyQuantity) {
								couponPrice =
									articlePriceWithoutOptions * +order.promoCode.value?.discount;
							} else {
								if (articleDiscountItem.quantity > 1) {
									const articlePriceWithDiscount =
										this.articlePriceWithoutOptions(
											articleDiscountItem,
											order.type,
											order.preorder.type,
											false
										);

									couponPrice =
										articlePriceWithDiscount * +order.promoCode.value?.discount;
								} else {
									couponPrice =
										articlePriceWithoutOptions *
										+order.promoCode.value?.discount;
								}
							}

							promoValue = promoValue + couponPrice;
						}
					});

					break;
				case PromoCodeType.ABSOLUTE_ARTICLE:
			}
		}
		return promoValue ?? 0;
	}
	static orderCouponValue(order: Order, deliveryFee: boolean, coupon: Coupon) {
		const articlePrices = +order.orderedArticles
			.map(orderedArticle => {
				const casted: any = orderedArticle;
				if (casted.totalPrice) {
					return numberD(casted.totalPrice);
				}
				return OrderUtils.totalPrice(
					orderedArticle,
					order.type,
					order.preorder?.type,
					order.terminalorder?.type
				);
			})
			.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0);
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let couponValue = 0;

		if (coupon && order?.coupon?.length > 0) {
			switch (coupon.type) {
				case CouponType.ABSOLUTE:
					couponValue = couponValue + coupon.value;
					break;
				case CouponType.RELATIVE:
					couponValue = couponValue + articlePrices * +coupon.value;
					break;
				case CouponType.DELIVERY_FEE:
					couponValue = couponValue + fees;
					break;
				case CouponType.FREE_ARTICLE:
					const articleFree = order.orderedArticles.find(
						articleGroup =>
							articleGroup.freeArticle &&
							coupon.value.includes(articleGroup?.article?._id)
					);
					if (articleFree) {
						const articlePriceFOfFree = this.totalPrice(
							articleFree,
							order.type,
							order.preorder.type
						);
						couponValue = couponValue + articlePriceFOfFree;
					}

					break;
				case CouponType.BOGO:
					couponValue =
						couponValue +
						OrderUtils.bogoPrice(
							order.orderedArticles.find(artGrp => artGrp.isPromo),
							order.type,
							order.preorder?.type
						);
					break;
				case CouponType.PLUS_ONE:
					const article = order.orderedArticles.find(
						articleGroup =>
							articleGroup.isPromo &&
							articleGroup.freeArticle &&
							coupon.value.includes(articleGroup?.article?._id)
					);
					if (article) {
						const articlePrice = this.totalPrice(
							article,
							order.type,
							order.preorder.type
						);
						couponValue = couponValue + articlePrice;
					}

					break;
				case CouponType.RELATIVE_ARTICLE:
					const articleDiscount = order.orderedArticles.find(
						articleGroup =>
							articleGroup.isPromo &&
							coupon.value?.articles?.includes(articleGroup?.article?._id)
					);
					if (articleDiscount) {
						const articlePrice = this.totalPrice(
							articleDiscount,
							order.type,
							order.preorder.type
						);
						const couponPrice = articlePrice * +coupon.value?.discount;

						couponValue = couponValue + couponPrice;
					}

					break;
			}
		}
		return couponValue ?? 0;
	}
	static orderTotalPrice(
		order: Order,
		deliveryFee: boolean,
		promoCode: boolean,
		coupon: boolean
	) {
		const articlePrices = +order.orderedArticles
			.map(orderedArticle => {
				const casted: any = orderedArticle;
				if (casted.totalPrice) {
					return numberD(casted.totalPrice);
				}
				return OrderUtils.totalPrice(
					orderedArticle,
					order.type,
					order.preorder?.type,
					order.terminalorder?.type
				);
			})
			.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0);
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let promoValue = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					promoValue = +order.promoCode.value;
					break;
				case PromoCodeType.RELATIVE:
					promoValue = articlePrices * +order.promoCode.value;
					break;
				case PromoCodeType.DELIVERY_FEE:
					promoValue = fees;
					break;
				case PromoCodeType.FREE_ARTICLE:
					promoValue = OrderUtils.totalPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.BOGO:
					promoValue = OrderUtils.bogoPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.RELATIVE_ARTICLE:
					const articleDiscountArray = order.orderedArticles.filter(
						articleGroup =>
							order.promoCode.value?.articles?.includes(
								articleGroup?.article?._id || articleGroup?.article?.masterId
							)
					);
					articleDiscountArray.forEach(articleDiscountItem => {
						if (articleDiscountItem) {
							let couponPrice = 0;

							const articlePriceWithoutOptions =
								this.articlePriceWithoutOptions(
									articleDiscountItem,
									order.type,
									order.preorder.type
								);

							if (order.promoCode.applyOnAnyQuantity) {
								couponPrice =
									articlePriceWithoutOptions * +order.promoCode.value?.discount;
							} else {
								if (articleDiscountItem.quantity > 1) {
									const articlePriceWithDiscount =
										this.articlePriceWithoutOptions(
											articleDiscountItem,
											order.type,
											order.preorder.type,
											false
										);

									couponPrice =
										articlePriceWithDiscount * +order.promoCode.value?.discount;
								} else {
									couponPrice =
										articlePriceWithoutOptions *
										+order.promoCode.value?.discount;
								}
							}

							promoValue = promoValue + couponPrice;
						}
					});

					break;
				case PromoCodeType.ABSOLUTE_ARTICLE:
			}
		}
		let couponValue = 0;
		if (coupon && order?.coupon?.length > 0) {
			order.coupon.forEach(it => {
				switch (it.type) {
					case CouponType.ABSOLUTE:
						couponValue = couponValue + it.value;
						break;
					case CouponType.RELATIVE:
						couponValue = couponValue + articlePrices * +it.value;
						break;
					case CouponType.DELIVERY_FEE:
						couponValue = couponValue + fees;
						break;
					case CouponType.FREE_ARTICLE:
						const articleFree = order.orderedArticles.find(
							articleGroup =>
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (articleFree) {
							const articlePriceFOfFree = this.totalPrice(
								articleFree,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePriceFOfFree;
						}

						break;
					case CouponType.BOGO:
						couponValue =
							couponValue +
							OrderUtils.bogoPrice(
								order.orderedArticles.find(artGrp => artGrp.isPromo),
								order.type,
								order.preorder?.type
							);
						break;
					case CouponType.PLUS_ONE:
						const article = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								articleGroup.freeArticle &&
								it.value.includes(articleGroup?.article?._id)
						);
						if (article) {
							const articlePrice = this.totalPrice(
								article,
								order.type,
								order.preorder.type
							);
							couponValue = couponValue + articlePrice;
						}

						break;
					case CouponType.RELATIVE_ARTICLE:
						const articleDiscount = order.orderedArticles.find(
							articleGroup =>
								articleGroup.isPromo &&
								it.value?.articles?.includes(articleGroup?.article?._id)
						);
						if (articleDiscount) {
							const articlePrice = this.totalPrice(
								articleDiscount,
								order.type,
								order.preorder.type
							);
							const couponPrice = articlePrice * +it.value?.discount;

							couponValue = couponValue + couponPrice;
						}

						break;
				}
			});
		}

		return articlePrices + fees - promoValue - couponValue;
	}
	static articlePriceWithoutOptions(
		articleGroup: ArticleGroup,
		orderType: OrderType,
		preorderType: PreorderType,
		allQuantaty: boolean = true
	): number {
		return (
			getPrice(articleGroup.article, orderType, preorderType) *
			(allQuantaty ? articleGroup.quantity : 1)
		);
	}
	static bogoPrice(
		articleGroup: ArticleGroup,
		orderType: OrderType,
		preorderType: PreorderType
	): number {
		const basePrice = getPrice(articleGroup.article, orderType, preorderType);
		const fullPriceOptions = articleGroup.groups.filter(artOpt =>
			articleGroup.article.groups.find(
				grp =>
					grp._id === artOpt.group &&
					grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0
			)
		);
		return (
			basePrice +
			fullPriceOptions
				.map(
					artOpt =>
						getPrice(artOpt.article, orderType, preorderType) * artOpt.quantity
				)
				.reduce(
					(previousValue, currentValue) => previousValue + currentValue,
					0
				)
		);
	}

	static isStandard(order: Order): boolean {
		return order && order?.type === OrderType.STANDARD;
	}

	static isCatering(order: Order): boolean {
		return order?.type === OrderType.CATERING;
	}

	static isPreorder(order: Order): boolean {
		return order?.type === OrderType.PREORDER && !!order?.preorder;
	}

	static isDelivery(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			order.preorder.type === PreorderType.DELIVERY
		);
	}

	static isParkCollect(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			order.preorder.type === PreorderType.PARK_COLLECT
		);
	}

	static isTakeAway(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			order.preorder.type === PreorderType.TAKE_AWAY
		);
	}

	static isInside(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			order.preorder.type === PreorderType.INSIDE
		);
	}

	static isFreeDeliveryPromo(order: Order): boolean {
		return (
			OrderUtils.isDelivery(order) &&
			OrderUtils.hasPromo(order) &&
			order.promoCode.type === PromoCodeType.DELIVERY_FEE
		);
	}

	static isBogoOrFreeArticlePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.BOGO ||
				order.promoCode.type === PromoCodeType.FREE_ARTICLE)
		);
	}

	static isAbsoluteOrRelativePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.RELATIVE ||
				order.promoCode.type === PromoCodeType.ABSOLUTE)
		);
	}

	static hasPromo(order: Order): boolean {
		return (
			order && order.promoCode && order.promoCode._id && order.promoCode.type
		);
	}

	static applyPromo(
		translate: TranslateService,
		venue: Venue,
		order: Order,
		promoCode: PromoCode,
		analytics: AnalyticsService
	): Order {
		if (OrderUtils.orderTotalPriceWithoutDiscounts(order) < promoCode.mov) {
			throw translate.instant('promo_code.mov_not_reached', {
				mov: numberToCurrency(promoCode.mov, order.currency)
			});
		}
		if (
			promoCode.type === PromoCodeType.DELIVERY_FEE &&
			!OrderUtils.isDelivery(order)
		) {
			throw translate.instant('promo_code.free_delivery_wrong_type');
		}
		switch (promoCode.type) {
			case PromoCodeType.BOGO:
				// promoCode.value is array of article masterIds
				const possibleBogos = order.orderedArticles.filter(
					artGrp =>
						promoCode.value.indexOf(artGrp.article.masterId) >= 0 ||
						promoCode.value.indexOf(artGrp.article._id) >= 0
				);
				if (possibleBogos.length === 0) {
					throw translate.instant('promo_code.no_bogo_in_cart');
				} else {
					let candidate = possibleBogos[0];
					let currentBogoPrice = OrderUtils.bogoPrice(
						candidate,
						order.type,
						order.preorder?.type
					);
					for (const possibleBogo of possibleBogos) {
						const possibleBogoPrice = OrderUtils.bogoPrice(
							possibleBogo,
							order.type,
							order.preorder?.type
						);
						if (possibleBogoPrice < currentBogoPrice) {
							currentBogoPrice = possibleBogoPrice;
							candidate = possibleBogo;
						}
					}
					const bogo: ArticleGroup = JSON.parse(JSON.stringify(candidate));
					bogo.quantity = 1;
					bogo.isPromo = true;
					OrderUtils.addToOrder(order, bogo, analytics);
				}
				break;
			case PromoCodeType.FREE_ARTICLE:
				const allArticles: Article[] = [];
				venue.articleCategories.forEach(cat =>
					allArticles.push(...cat.articles)
				);
				// promoCode.value is  article masterId
				const freeArticle = allArticles.find(
					art => promoCode.value.indexOf(art.masterId) >= 0
				);
				const freeArticleGroup = new ArticleGroup();
				freeArticleGroup.article = freeArticle;
				freeArticleGroup.quantity = 1;
				freeArticleGroup.isPromo = true;
				freeArticleGroup.freeArticle = true;
				OrderUtils.addToOrder(order, freeArticleGroup, analytics);
				break;
		}
		order.promoCode = promoCode;
		return order;
	}

	static removePromo(order: Order): Order {
		const removedPromo = order.promoCode;
		order.promoCode = null;
		if (!removedPromo) {
			return order;
		}
		switch (removedPromo.type) {
			case PromoCodeType.DELIVERY_FEE:
			case PromoCodeType.ABSOLUTE:
			case PromoCodeType.RELATIVE:
				break;
			case PromoCodeType.RELATIVE_ARTICLE || PromoCodeType.ABSOLUTE_ARTICLE:
				break;
			case PromoCodeType.BOGO:
			case PromoCodeType.FREE_ARTICLE:
				order.orderedArticles = order.orderedArticles.filter(
					artGrp => !artGrp.isPromo
				);
				break;
		}
		return order;
	}

	static orderTotalPriceWithoutDiscounts(order: Order): number {
		return this.orderTotalPrice(order, true, false, false);
	}

	static articleGroupsTotalPrice(
		articleGroups: ArticleGroup[],
		orderType: OrderType,
		preorderType: PreorderType,
		terminalorderType: TerminalorderType = null
	): number {
		return articleGroups
			.map(articleGroup =>
				OrderUtils.totalPrice(
					articleGroup,
					orderType,
					preorderType,
					terminalorderType
				)
			)
			.reduce((prev, curr) => prev + curr, 0);
	}

	static totalPrice(
		articleGroup: ArticleGroup,
		orderType: OrderType,
		preorderType: PreorderType,
		terminalOrderType: TerminalorderType = null
	): number {
		return (
			(+getPrice(
				articleGroup.article,
				orderType,
				preorderType,
				terminalOrderType,
				articleGroup
			) +
				articleGroup.groups
					.map(option => {
						return (
							+getPrice(
								option.article,
								orderType,
								preorderType,
								terminalOrderType,
								articleGroup
							) * option.quantity
						);
					})
					.reduce((prev, curr) => prev + curr, 0)) *
			articleGroup.quantity
		);
	}

	static injectRequiredArticles(
		venue: Venue,
		order: Order,
		analytics: AnalyticsService
	) {
		if (!venue || !order) {
			return;
		}
		const requiredArticles = getRequiredArticles(venue, order.preorder?.type);
		const requiredArticleGroups: ArticleGroup[] = [];
		for (const requiredArticle of requiredArticles) {
			if (
				!order.orderedArticles.find(
					oa => oa.article._id === requiredArticle._id
				)
			) {
				const ag = new ArticleGroup();
				ag.article = requiredArticle;
				ag.groups = defaultsToArticleOption(
					requiredArticle,
					ag.groups,
					requiredArticle.defaults,
					order.type,
					order.preorder?.type
				);
				ag.quantity = 1;
				requiredArticleGroups.push(ag);
			}
		}
		for (const requiredArticleGroup of requiredArticleGroups) {
			OrderUtils.addToOrder(order, requiredArticleGroup, analytics);
		}
	}

	static injectDeliveryFees(venue: Venue, order: Order) {
		if (!venue || !order) {
			if (OrderUtils.isPreorder(order)) {
				order.preorder.deliveryFee = undefined;
			}
			return;
		}
		if (!OrderUtils.isPreorder(order)) {
			return;
		}
		if (!OrderUtils.isDelivery(order)) {
			order.preorder.deliveryFee = undefined;
			return;
		}
		const articleSum = OrderUtils.articleGroupsTotalPrice(
			order.orderedArticles,
			order.type,
			order.preorder?.type,
			order.terminalorder?.type
		);
		order.preorder.deliveryFee = this.findMovAndFees(
			venue,
			order,
			articleSum
		).fee;
	}

	static findFees(venue: Venue, order: Order, totalPrice: number): number {
		return OrderUtils.findMovAndFees(venue, order, totalPrice).fee;
	}

	static findMov(venue: Venue, order: Order, totalPrice: number): number {
		return OrderUtils.findMovAndFees(venue, order, totalPrice).mov;
	}

	static addSingle(selectedOptions: ArticleOption[], option: ArticleOption) {
		const relevantOptions = selectedOptions.filter(
			value =>
				value.group !== option.group ||
				value.dependencyNumber !== option.dependencyNumber
		);
		while (selectedOptions.length) {
			selectedOptions.pop();
		}
		selectedOptions.push(...relevantOptions);
		option.quantity = 1;
		selectedOptions.push(option);
	}

	static addToOrder(
		order: Order,
		articleGroup: ArticleGroup,
		analytics: AnalyticsService,
		isLoayltyArticle: boolean = false
	) {
		const currentPromoIndex = order.orderedArticles.findIndex(oa => oa.isPromo);
		if (
			currentPromoIndex >= 0 &&
			OrderUtils.isBogoOrFreeArticlePromo(order) &&
			order.promoCode.type === PromoCodeType.BOGO &&
			order.promoCode.value.indexOf(articleGroup.article.masterId) >= 0 &&
			OrderUtils.bogoPrice(articleGroup, order.type, order.preorder.type) <
				OrderUtils.bogoPrice(
					order.orderedArticles[currentPromoIndex],
					order.type,
					order.preorder.type
				)
		) {
			// new article is added and is bogo and is the smallest bogo price so there is no copy of this article.
			// remove isPromo Flag from previous article
			// add new article twice (once with isPromo true)
			order.orderedArticles[currentPromoIndex].isPromo = false;
			order.orderedArticles.push(articleGroup);
			const bogo = JSON.parse(JSON.stringify(articleGroup));
			bogo.isPromo = true;
			order.orderedArticles.push(bogo);
			analytics.addToCart(order, articleGroup);
			return;
		}
		const index = order.orderedArticles.findIndex(orderedArticle => {
			return (
				orderedArticle.article._id === articleGroup.article._id &&
				articleGroup.groups.length === orderedArticle.groups.length &&
				articleGroup.isPromo === orderedArticle.isPromo &&
				orderedArticle.redeemWithPiggyPoints === isLoayltyArticle &&
				articleGroup.groups
					.map(
						option =>
							orderedArticle.groups.findIndex(
								orderedOption =>
									option.article._id === orderedOption.article._id &&
									option.quantity === orderedOption.quantity &&
									option.dependency === orderedOption.dependency &&
									option.dependsOn === orderedOption.dependsOn &&
									option.dependencyNumber === orderedOption.dependencyNumber
							) >= 0
					)
					.reduce(
						(previousValue, currentValue) => previousValue && currentValue,
						true
					)
			);
		});

		if (index >= 0) {
			// if (
			// 	order.orderedArticles[index]?.redeemWithPiggyPoints &&
			// 	!articleGroup?.redeemWithPiggyPoints
			// ) {
			// 	order.orderedArticles.push(articleGroup);
			// 	analytics.addToCart(order, articleGroup);
			// 	return;
			// }
			order.orderedArticles[index].quantity++;
			order.orderedArticles[index].isRecommendedRecipe =
				order.orderedArticles[index].isRecommendedRecipe ||
				articleGroup.isRecommendedRecipe;
		} else {
			order.orderedArticles.push(articleGroup);
			analytics.addToCart(order, articleGroup);
		}
	}

	static addOption(
		options: ArticleOption[],
		option: ArticleOption,
		group: OptionGroup,
		dependency: FulfilledDependency,
		dependencyNumber: number
	) {
		if (!dependency) {
			console.error('No dependency');
			return;
		}
		if (dependency.times < 0) {
			console.log('Not adding option. Dependency not fulfilled');
			return;
		}
		option.dependencyNumber = dependencyNumber;
		if (dependency.times > 0) {
			option.dependsOn = dependency.dependsOn;
			option.dependency = dependency.dependency._id;
		}
		const selection = filterMatchingOptions(
			options,
			group,
			dependency,
			dependencyNumber
		);
		if (group.limit === 1) {
			OrderUtils.addSingle(options, option);
			return;
		}
		const emptyIndex = options.findIndex(
			emptyOption =>
				emptyOption.group === group._id &&
				TagUtils.hasEmptyTag(emptyOption.article)
		);
		const count =
			selection
				.map(value => value.quantity)
				.reduce(
					(previousValue, currentValue) => previousValue + currentValue,
					0
				) + option.quantity;
		if (count > group.limit && group.limit !== 0) {
			const indexOfFirst = options.indexOf(
				selection.find(value => value.article._id !== option.article._id)
			);
			if (indexOfFirst >= 0) {
				if (options[indexOfFirst].quantity > 1) {
					options[indexOfFirst].quantity -= 1;
				} else {
					options.splice(indexOfFirst, 1);
				}
			}
		}
		const index = options.findIndex(
			value => value.article._id === option.article._id
		);
		if (index >= 0 && !group.hasMultiple) {
			removeFromArray(options, index);
		} else if (
			option.group === group._id &&
			option.article.tags &&
			option.article.tags.find(tag => tag.identifier === 'empty')
		) {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			} else {
				OrderUtils.addSingle(options, option);
			}
		} else {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			}
			const matchedIndex = options.findIndex(optionToMatch => {
				return (
					optionToMatch.group === option.group &&
					optionToMatch.article._id === option.article._id &&
					optionToMatch.dependency === option.dependency &&
					optionToMatch.dependsOn === option.dependsOn &&
					optionToMatch.dependencyNumber === option.dependencyNumber
				);
			});
			if (matchedIndex >= 0) {
				const requirements = options[matchedIndex].article.requirements;
				if (requirements) {
					if (
						(requirements.min !== -1 &&
							requirements.min >
								options[matchedIndex].quantity + option.quantity) ||
						(requirements.max !== -1 &&
							requirements.max <
								options[matchedIndex].quantity + option.quantity)
					) {
						console.log({
							message: 'Could not remove or add option',
							requirements,
							option: option.article.name.de,
							presentQuantity: options[matchedIndex].quantity,
							modifyBy: option.quantity
						});
						return;
					}
				}
				options[matchedIndex].quantity += option.quantity;
				if (options[matchedIndex].quantity <= 0) {
					removeFromArray(options, matchedIndex);
				}
			} else {
				options.push(option);
			}
		}
		options = options.filter(option => {
			if (!option.dependsOn) return true;
			const parentsCount = options
				.filter(parent => parent.article._id === option.dependsOn)
				.map(parent => parent.quantity)
				.reduce((prev, curr) => prev + curr, 0);
			const keep =
				parentsCount >= option.dependencyNumber ||
				option.dependencyNumber === 0;
			if (!keep) {
				console.log('Remove:', {
					parentsCount,
					dependencyNumber: option.dependencyNumber
				});
			}
			return keep;
		});
		console.log(
			options.map(opt => ({
				quantity: opt.quantity,
				name: opt.article.name.de,
				dependencyNumber: opt.dependencyNumber
			}))
		);
	}

	static getDeliveryFees(order: Order): number {
		if (!OrderUtils.isDelivery(order)) {
			return 0;
		}
		if (!order.preorder.deliveryFee) {
			return 0;
		}
		return numberD(order.preorder.deliveryFee);
	}

	static validateOrder(
		venue: Venue,
		order: Order
	): {valid: boolean; movDifference: number} {
		const orderValue = OrderUtils.orderTotalPrice(order, false, true, true);
		let mov =
			order.preorder?.type === PreorderType.DELIVERY
				? OrderUtils.findMov(venue, order, orderValue)
				: 0;
		let articlesValid = order.orderedArticles.length !== 0;
		if (OrderUtils.hasPromo(order)) {
			mov = Math.max(mov, +order.promoCode.mov);
		}
		for (const oa of order.orderedArticles) {
			if (!ValidationUtils.areGroupsValid(oa, oa.article.groups)) {
				articlesValid = false;
				break;
			}
		}
		return {
			valid: orderValue > mov && articlesValid,
			movDifference: orderValue - mov
		};
	}

	static slotConflictingArticlesInOrder(
		slot: Moment,
		order: Order
	): ArticleGroup[] {
		const conflictingArticles: ArticleGroup[] = [];
		for (const articleGroup of order.orderedArticles) {
			if (
				!TimeUtils.doesHoursMatch(slot, articleGroup.article.availableHours)
			) {
				conflictingArticles.push(articleGroup);
			}
		}
		return conflictingArticles;
	}

	private static findMovAndFees(
		venue: Venue,
		order: Order,
		totalPrice: number
	): {mov: number; fee: number} {
		if (!venue) {
			return undefined;
		}
		if (!OrderUtils.isDelivery(order)) {
			return undefined;
		}
		if (!venue.movAndFee) {
			return {
				mov: numberD(venue.movDelivery) ?? 0,
				fee: OrderUtils.findDeliveryFeesDeprecated(venue, order, totalPrice)
			};
		}
		if (venue.deliveryByRadius) {
			const distance = calculateGeoDistance(
				order.preorder.lat,
				order.preorder.lng,
				venue.location.coordinates[1],
				venue.location.coordinates[0]
			);
			if (!venue.movAndFee.byRadius || venue.movAndFee.byRadius.length === 0) {
				console.log('No movAndFee.byRadius taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee)
				};
			}
			const byRadius = venue.movAndFee.byRadius.reduce((prev, curr) => {
				return curr.radius >= distance && curr.radius < prev.radius
					? curr
					: prev;
			});
			if (!byRadius) {
				console.log('No specific fees found taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee)
				};
			}
			return {
				mov: numberD(byRadius ? byRadius.mov : venue.movAndFee.mov),
				fee: numberD(
					OrderUtils.findFee(byRadius.fees, totalPrice)?.fee ??
						venue.movAndFee.fee
				)
			};
		} else {
			const postalCode = order.preorder.postalCode;
			console.log({postalCode});
			if (
				!venue.movAndFee.byPostalCodes ||
				venue.movAndFee.byPostalCodes.length === 0
			) {
				console.log('No movAndFee.byPostalCodes taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee)
				};
			}
			const byPostalCode = venue.movAndFee.byPostalCodes.find(
				bpc => bpc.postalCode === postalCode
			);

			if (!byPostalCode) {
				console.log('No specific fees found taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee)
				};
			}
			return {
				mov: numberD(byPostalCode ? byPostalCode.mov : venue.movAndFee.mov),
				fee: numberD(
					OrderUtils.findFee(byPostalCode.fees, totalPrice)?.fee ??
						venue.movAndFee.fee
				)
			};
		}
	}

	private static findFee(fees: Fee[], totalPrice: number): Fee {
		const relevantFees = fees.filter(f => numberD(f.from) <= totalPrice);
		if (relevantFees.length === 0) {
			return null;
		}
		return relevantFees.reduce((prev, curr) => {
			return numberD(prev.from) > numberD(curr.from) ? prev : curr;
		});
	}

	private static findDeliveryFeesDeprecated(
		venue: Venue,
		order: Order,
		totalPrice: number
	): number {
		let candidate: any;
		if (
			order.preorder?.postalCode &&
			venue.deliveryFeesPostalCodes &&
			venue.deliveryPostalCodes.length > 0
		) {
			candidate = venue.deliveryFeesPostalCodes.find(
				fee => fee.postalCode === order.preorder?.postalCode
			);
			if (candidate) {
				return numberD(candidate.fee);
			}
		}
		if (venue.deliveryFees.length < 1) {
			return undefined;
		}
		candidate = venue.deliveryFees[0];
		for (const deliveryFee of venue.deliveryFees) {
			if (
				numberD(deliveryFee.from) <= totalPrice &&
				numberD(candidate.fee) > numberD(deliveryFee.fee)
			) {
				candidate = deliveryFee;
			}
		}
		return numberD(candidate?.fee);
	}
	static calcDiscountOfCoupon(order: Order, coupon: Coupon) {
		const articlePrices = this.orderTotalPrice(order, true, true, false);
		let couponValue = 0;
		if (coupon) {
			switch (coupon.type) {
				case CouponType.ABSOLUTE:
					couponValue = couponValue + coupon.value;
					break;
				case CouponType.RELATIVE:
					couponValue = couponValue + articlePrices * +coupon.value;
					break;

				case CouponType.FREE_ARTICLE:
					const articleFree = order.orderedArticles.find(
						it => it.freeArticle && coupon.value.includes(it?.article?._id)
					);
					if (articleFree) {
						const articlePriceFOfFree = this.totalPrice(
							articleFree,
							order.type,
							order.preorder.type
						);
						couponValue = couponValue + articlePriceFOfFree;
					}

					break;
				case CouponType.BOGO:
					couponValue =
						couponValue +
						OrderUtils.bogoPrice(
							order.orderedArticles.find(artGrp => artGrp.isPromo),
							order.type,
							order.preorder?.type
						);
					break;
				case CouponType.PLUS_ONE:
					const article = order.orderedArticles.find(
						it =>
							it.isPromo &&
							it.freeArticle &&
							coupon.value.includes(it?.article?._id)
					);
					if (article) {
						const articlePrice = this.totalPrice(
							article,
							order.type,
							order.preorder.type
						);
						couponValue = couponValue + articlePrice;
					}

					break;
				case CouponType.RELATIVE_ARTICLE:
					const articleDiscount = order.orderedArticles.find(
						articleGroup =>
							articleGroup.isPromo &&
							coupon.value?.articles?.includes(articleGroup?.article?._id)
					);
					if (articleDiscount) {
						const articlePrice = this.totalPrice(
							articleDiscount,
							order.type,
							order.preorder.type
						);
						const couponPrice = articlePrice * +coupon.value?.discount;

						couponValue = couponValue + couponPrice;
					}

					break;
			}
		}

		return couponValue;
	}
}

export const filterMatchingOptions = (
	options: ArticleOption[],
	group: OptionGroup,
	dependency: FulfilledDependency,
	dependencyNumber: number
) => {
	return options.filter(value => {
		return (
			group._id === value.group &&
			dependencyNumber === value.dependencyNumber &&
			(dependency.dependency?._id === value.dependency ||
				(!dependency.dependency && !value.dependency))
		);
	});
};
export const hasPromo = (order: Order) => {
	return (
		order && order.promoCode && order.promoCode._id && order.promoCode.type
	);
};

export const removePromo = (order: Order) => {
	const removedPromo = order.promoCode;
	order.promoCode = null;
	if (!removedPromo) {
		return order;
	}
	switch (removedPromo.type) {
		case PromoCodeType.DELIVERY_FEE:
		case PromoCodeType.ABSOLUTE:
		case PromoCodeType.RELATIVE:
			break;
		case PromoCodeType.BOGO:
		case PromoCodeType.FREE_ARTICLE:
			order.orderedArticles = order.orderedArticles.filter(
				artGrp => !artGrp.isPromo
			);
			break;
	}
	return order;
};
